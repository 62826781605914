import React, { FC, PropsWithChildren } from "react";

import { Box, Divider, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import getConfig from "next/config";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { Heading } from "@components/Heading/Heading";
import Image from "@components/Image";
import { IBreadcrumbItemType } from "@components/PageBreadcrumb/PageBreadcrumb";
import Meta from "@components/seo/Meta";
import useDateFormat from "@utils/hooks/useDateFormat";
import PageBreadcrumb from "components/PageBreadcrumb";

import CategoryTag from "../CategoryTag";
import PublishDate from "../PublishDate";
import { TBlogWithTitle } from "../types";
import ShareBlogSocials from "./ShareBlogSocials";

const PopularTag = dynamic(() => import("@components/blog/PopularTag"), { ssr: false });
const SimilarBlogs = dynamic(() => import("../SimilarBlogs"), { ssr: false });
const { publicRuntimeConfig } = getConfig();

interface IBlogDetailsProps {
  blog?: TBlogWithTitle;
}

const BlogDetails: FC<PropsWithChildren<IBlogDetailsProps>> = ({ blog, children }) => {
  const formatDate = useDateFormat();
  const { asPath, locale } = useRouter();
  const { t } = useTranslation();

  if (!blog) {
    return null;
  }

  const url = `${publicRuntimeConfig.nextAuthUrl}${asPath}`;
  const {
    blogCategory,
    publishedAt,
    title,
    images,
    popular: isPopular,
    similarBlogPosts,
    metaDescription,
    metaKeywords,
    metaTitle,
  } = blog;
  const publishDate = formatDate(publishedAt, "fullMonthDayYear");
  const blogMainImage = images?.[0]?.path;

  const breadcrumbItems: IBreadcrumbItemType[] = [
    { title: t("navigation:home"), link: "/" },
    { title: t("navigation:blog"), link: "/blog" },
    { title: blogCategory.name || "Category", link: `/${blogCategory.slug}` },
    { title: blog?.title || "", link: "#", current: true },
  ];

  return (
    <>
      <Meta
        {...{
          title: metaTitle ?? title,
          description: metaDescription,
          image: blogMainImage,
          keywords: metaKeywords,
          locale,
          url,
        }}
      />
      <PageBreadcrumb breadcrumbItems={breadcrumbItems} />
      <Box>
        <CategoryTag
          href={blogCategory.slug}
          name={`#${blogCategory.name}`}
          linkProps={{
            color: "gray.500",
            fontSize: "textSize.bodyText",
          }}
        />
        <Heading as="h2" textTransform="none">
          {title}
        </Heading>
        <VStack spacing={8}>
          <PublishDate date={publishDate} textProps={{ w: "full" }} />
          <ShareBlogSocials url={url} />
          {blogMainImage && (
            <Box
              w={{ base: "100vw", md: "full" }}
              position="relative"
              minH={{ base: 193, md: 500 }}
              maxW={{ base: "100vw", md: undefined }}
            >
              <Image
                src={blogMainImage}
                layout="fill"
                objectFit="cover"
                borderRadius={{ base: undefined, md: "default" }}
                alt={title}
              />
              {isPopular && <PopularTag />}
            </Box>
          )}
          <Box w="full">
            <Box
              mx={{
                base: -6,
                md: -8,
              }}
            >
              {children}
            </Box>
          </Box>
          {similarBlogPosts && similarBlogPosts.length > 0 && (
            <>
              <Divider borderColor="blackAlpha.300" />
              <SimilarBlogs data={similarBlogPosts} />
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default BlogDetails;
